@keyframes scrollDown
  0%
    transform: rotate(45deg) skew(-20deg, -20deg) translate3d(-60%, -60%, 0)
    opacity: 0
    border-color: var(--c-blue-20pcnt)

  25%
    transform: rotate(45deg) skew(-20deg, -20deg) translate3d(-20%, -20%, 0)
    opacity: 1
    border-color: var(--c-blue-20pcnt)

  50%
    transform: rotate(45deg) skew(-20deg, -20deg) translate3d(20%, 20%, 0)
    opacity: 1
    border-color: var(--c-red-20pcnt)

  75%
    transform: rotate(45deg) skew(-20deg, -20deg) translate3d(60%, 60%, 0)
    opacity: 0
    border-color: var(--c-white-20pcnt)

  100%
    transform: rotate(45deg) skew(-20deg, -20deg) translate3d(-60%, -60%, 0)
    opacity: 0
    border-color: var(--c-white-20pcnt)
