\:root
  --c-blue: #428bca
  --c-red: #c30000
  --c-white: #fff
  --c-black: #000

  --c-blue-transp: #428bca00
  --c-blue-50pcnt: #428bca7f
  --c-blue-20pcnt: #428bca33
  --c-blue-10pcnt: #428bca19

  --c-red-transp: #c3000000
  --c-red-50pcnt: #c300007f
  --c-red-20pcnt: #c3000033
  --c-red-10pcnt: #c3000019

  --c-white-transp: #ffffff00
  --c-white-50pcnt: #ffffff7f
  --c-white-20pcnt: #ffffff33
  --c-white-10pcnt: #ffffff19



  --a-norm: 250
  --a-fast: 125
  --a-slow: 500

  --p-content: 4vmin
  --h-header-sticky: 6vh
