@import "normalize.css"
@import "./variables.sass"
@import "./animations.sass"

\:root
  box-sizing: border-box

body
  font: 16px 'Open Sans', Helvetica, Arial, sans-serif
  margin: 0

a
  text-decoration: none
  color: var(--c-blue)

.splash
  position: relative
  z-index: 10
  min-height: 100vh
  background: #fffc radial-gradient(at 0 100%, var(--c-blue-20pcnt), var(--c-blue-transp) 75%)

.header
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center
  min-height: 100vh

  &.is-sticky
    position: fixed
    top: 0
    width: 100%
    min-height: var(--h-header-sticky)

    // .splash.is-sticky > &
      // background: #fffe radial-gradient(at 0 100%, var(--c-blue-20pcnt), var(--c-blue-transp))

    &::after
      content: ''
      position: absolute
      top: 0
      right: 0
      left: 0
      height: calc(1.8 * var(--h-header-sticky))
      background: linear-gradient(to bottom, #fff, #fffb 50%, #fff0)
      z-index: -1
      pointer-events: none

.wappen
  display: inline-flex
  justify-content: center
  margin: 15vh 0 5vh

  img
    width: 39vmin
    height: 52vmin
    max-width: 240px
    max-height: 320px

  .header.is-sticky &
    overflow: hidden
    margin: 0
    height: 0

.logo
  width: 100%
  height: 10vh
  padding: 0 10vw
  box-sizing: border-box
  position: relative

  .header.is-sticky &
    height: var(--h-header-sticky)

  svg
    // background-color: lightgray
    position: absolute
    left: 50%
    top: 50%
    width: 80vmin
    max-width: 480px
    transition: all calc(1ms * var(--a-norm))
    transform: translate3d(-50%, -50%, 0)
    transform-origin: 0 50%

    .header.is-sticky &
      left: var(--p-content)
      transform: translate3d(0, -50%, 0) scale(0.5)

  &__Farben
    transition: all calc(1ms * var(--a-norm))

    .header.is-sticky &
      transform: translateY(22%)

  &__AV
    transform-origin: 0 50%
    transition: all calc(1ms * var(--a-norm))

    .header.is-sticky &
      opacity: 0
      transform: scale(0.5)

  &__HanseaBerlin
    transition: all calc(1ms * var(--a-norm))

    .header.is-sticky &
      transform: translateX(-16.2%) translateY(22%)

  &__zuKoelnImCV
    transition: all calc(1ms * var(--a-norm))

    .header.is-sticky &
      opacity: 0
      transform: translateX(-16.2%) translateY(22%)

.scroll-down
  position: relative
  display: inline-block
  box-sizing: border-box
  width: 18vmin
  height: 10vmin
  margin-bottom: 10vh
  // background: #0003

  &::before,
  span,
  &::after
    content: ''
    position: absolute
    left: 50%
    margin-left: -3vmin
    width: 6vmin
    height: 6vmin
    border: solid var(--c-blue-20pcnt)
    border-width: 0 0.6vmin 0.6vmin 0
    transform-origin: 50% 50%
    transform: rotate(45deg) skew(-20deg, -20deg) translate3d(-60%, -60%, 0)
    opacity: 0
    animation: scrollDown linear 3s infinite

  span
    animation-delay: .5s
  &::after
    animation-delay: 1s

  &:focus,
  &:hover
    border-color: var(--c-blue)

  &:focus
    outline: none
    animation: none

  .header.is-sticky &
    position: absolute
    overflow: hidden
    height: 0
    margin: 0
    opacity: 0
    animation: none

.nav
  position: absolute
  right: 0
  bottom: 0
  height: var(--h-header-sticky)
  width: 4vh
  opacity: 0
  transition: opacity calc(var(--a-norm) * 1ms)
  pointer-events: none

  --f-nav: 5vh

  @media (min-height: 400px)
    --f-nav: 20px

  .header.is-sticky &
    opacity: 1
    pointer-events: all

  &__quick
    position: absolute
    top: 0
    right: calc(var(--p-content) + 4vh)
    height: var(--h-header-sticky)
    display: flex
    white-space: nowrap
    align-items: center

    @media (max-width: 750px)
      display: none

  &__quick &__link
    margin-right: .5vw
    padding: 0 calc(var(--p-content) / 5)
    font-size: calc(var(--f-nav) * .8)

    @media (max-width: 900px)
      &:nth-child(1)
        display: none

  &__trigger
    position: absolute
    top: 50%
    right: var(--p-content)
    height: 4vh
    width: 4vh
    border: 0
    margin: 0
    background: transparent
    outline: none
    border: 1px solid var(--c-blue-transp)
    transform-origin: 0 0
    transform: skewX(-12deg) translateY(-50%)
    cursor: pointer

    &::before,
    span,
    &::after
      content: ''
      display: block
      position: absolute
      top: 50%
      box-sizing: border-box
      background-color: var(--c-black)
      transition: all calc(var(--a-norm) * 1ms)

    &::before,
    span,
    &::after
      left: 0.3vh
      right: 0.3vh
      height: 0.3vh
    &::before
      transform: translateY(calc(-50% + .75vh))
    span
      transform: translateY(calc(-50%))
    &::after
      transform: translateY(calc(-50% - .75vh))

    &.is-pressed
      &::before
        transform: translateY(calc(-50%)) rotate(45deg)
      span
        opacity: 0
      &::after
        transform: translateY(calc(-50%)) rotate(-45deg)

    @media (min-width: 750px)
      &::before,
      span,
      &::after
        left: 50%
        height: 0.3vh
        width: 0.3vh
      &::before
        transform: translateX(calc(-50% + .75vh))
      span
        transform: translateX(calc(-50%))
      &::after
        transform: translateX(calc(-50% - .75vh))

      &.is-pressed
        &::before,
        span,
        &::after
          left: 0
          right: 0
          width: inherit
        &::before
          transform: translate3d(0, -50%, 0) rotate(45deg)
        span
          opacity: 0
        &::after
          transform: translate3d(0, -50%, 0) rotate(-45deg)

    &:focus:not(:hover)
      border: 1px solid var(--c-blue)

  &__menu
    position: absolute
    top: 0
    right: 0
    list-style: none
    margin: 0
    padding: calc(4 * var(--p-content) / 5)
    background: #fffe //linear-gradient(90deg, var(--c-blue-20pcnt), var(--c-blue-transp))
    opacity: 0
    pointer-events: none
    transform: translateY(var(--h-header-sticky))
    transition: all calc(var(--a-slow) * 1ms)
    overflow: hidden

  &__trigger.is-pressed + &__menu
    opacity: 1
    // transform: translateY(var(--h-header-sticky))
    pointer-events: all

  &__item
    padding: calc(var(--f-nav) * .25) 0
    text-align: right

    @media (min-width: 750px)
      &:nth-child(-n + 4)
        display: none

  &__link
    position: relative
    display: inline-block
    font-weight: 300
    font-style: italic
    text-transform: uppercase
    background-color: var(--c-blue-transp)
    color: var(--c-black)
    transition: all calc(var(--a-fast) * 1ms)
    outline: none

    em
      font-weight: 800

    &::after
      content: ''
      position: absolute
      left: 0
      top: 0
      right: 0
      bottom: 0
      z-index: -1
      box-sizing: border-box
      border: 1px solid var(--c-blue-transp)
      transform: skewX(-12deg)
      transition: all calc(var(--a-fast) * 1ms)

    &:hover
      color: var(--c-blue)
      box-shadow: none

      &::after
        background: #fffe linear-gradient(90deg, var(--c-blue-10pcnt), var(--c-blue-10pcnt))

    &:hover::after,
    &:focus::after
      border: 1px solid var(--c-blue-20pcnt)

  &__item &__link
    padding: 0 calc(var(--p-content) / 5)
    font-size: calc(var(--f-nav) * .8)

  &__trigger + &__menu &__link
    transform: translateX(150%)
    transition: all calc(var(--a-fast) * 1ms)

  &__trigger.is-pressed + &__menu > &__item > &__link
    transform: translateX(0)

  @for $i from 0 through 15
    &__trigger.is-pressed + &__menu > &__item:nth-child(#{$i}) > &__link
      transition: all calc(var(--a-fast) * 1ms), transform calc(var(--a-slow) * 1ms) #{$i * 33}ms

.social
  position: sticky
  left: calc(50% + 1000px / 2 - var(--p-content) * 1.5)
  top: calc(var(--h-header-sticky) * 2)
  z-index: 6
  height: 0
  width: var(--p-content)
  transform: translateY(var(--h-header-sticky))

  @media (max-width: 1000px)
    transform: translateY(var(--h-header-sticky)) translateX(-50%)

  &__media
    position: relative
    display: flex
    justify-content: center
    align-items: center
    padding: calc(var(--p-content) * 0.2)
    margin-bottom: calc(var(--p-content) * 0.5)
    transition: opacity calc(var(--a-fast) * 1ms)
    opacity: 0.5

    &:hover
      opacity: 1

    &::after
      content: ''
      position: absolute
      left: 0
      top: 0
      bottom: 0
      right: 0
      z-index: -1
      background-color: var(--c-blue-10pcnt)
      transform-origin: 50%
      transform: skewX(-12deg)

    svg
      height: calc(var(--p-content) * 0.6)
      width: calc(var(--p-content) * 0.6)

    &--facebook
      svg path
        fill: var(--c-white)
      &::after
        background-color: #3b5998

    &--twitter
      svg path
        fill: var(--c-white)
      &::after
        background-color: #4aa0ec

    &--instagram
      svg path,
      svg circle
        fill: var(--c-white)
      &::after
        background-color: #517fa6

  @media (max-width: 750px)
    --p-content: calc(var(--h-header-sticky) * 0.5)

    display: flex
    position: fixed
    top: calc(var(--h-header-sticky) / 2)
    left: 50%
    z-index: 12
    transform: translateY(-50%)
    height: var(--p-content)
    opacity: 0
    pointer-events: none
    transition: opacity calc(var(--a-fast) * 1ms)

    .splash.is-sticky + &
      opacity: 1

    &__media
      margin-bottom: 0
      margin-right: calc(var(--p-content) * 0.5)
      opacity: 1

.page
  min-height: 50vh
  box-sizing: border-box

  --f-base: 1vw

  @media (max-width: 750px)
    --f-base: 7.5px

  @media (min-width: 1000px)
    --f-base: 10px

  &__content
    position: relative
    padding: var(--h-header-sticky) calc(var(--p-content) * 2) calc(var(--p-content) * 3) var(--p-content)
    max-width: 1000px
    margin: 0 auto
    font-size: calc(var(--f-base) * 2)
    box-sizing: border-box

    @media (max-width: 750px)
      padding-right: var(--p-content)

    &::after
      content: ''
      position: absolute
      left: 0
      top: 0
      right: 0
      bottom: 0
      z-index: 9
      background-color: #fff0
      pointer-events: none
      transition: all calc(var(--a-norm) * 1ms)

      .is-menu-shown &
        background-color: #fff9
        pointer-events: all

    & > :first-child
      margin-top: var(--p-content)

    a
      display: inline-block
      transition: background-color calc(var(--a-fast) * 1ms), box-shadow calc(var(--a-fast) * 1ms)

      &:hover
        box-shadow: inset 0 -2px 0 0 var(--c-blue)

      &.nav__link
        position: relative
        padding: 0 calc(var(--f-base) * .5)

        &:hover
          box-shadow: none

      &[href^="mailto:"] > s
        display: inline-block
        overflow: hidden
        width: 0
        height: 0

    h1, h2, h3, h4
      font-weight: 300
      font-style: italic

    h1, h2
      margin: calc(var(--f-base) * 4) 0

    h3, h4
      margin: calc(var(--f-base) * 3) 0 calc(var(--f-base) * -1)

    h1
      font-size: calc(var(--f-base) * 3.6)

    h2
      font-size: calc(var(--f-base) * 3.2)
      text-transform: uppercase
      letter-spacing: 0.05em

    h1, h2, h3, h4
      em
        font-weight: 800
      span
        text-transform: none
        letter-spacing: 0
        white-space: nowrap

    h3
      --h-stripe: calc(var(--f-base) * 1.5)

      display: flex
      flex-wrap: none
      font-size: calc(var(--f-base) * 2.4)
      color: var(--c-red)

      em
        font-weight: 700

      &::before,
      &::after
        content: ''
        display: block
        height: var(--h-stripe)
        margin-top: calc(var(--f-base) * 1.2)
        background-color: var(--c-red-10pcnt)
        transform: skewX(-12deg)
      &::before
        margin-right: calc(var(--f-base) * 1)
        width: calc(var(--f-base) * 3)
      &::after
        margin-left: calc(var(--f-base) * 1)
        flex: 1

      &:target
        padding-top: calc(var(--h-header-sticky) * 1.5)

    h4
      font-size: calc(var(--f-base) * 2)
      color: var(--c-blue)
      em
        font-weight: 700

    ul
      list-style: none
      padding-left: calc(var(--f-base) * 2.4)

      li
        position: relative
        margin: var(--f-base) 0

        &::before
          content: ''
          display: block
          position: absolute
          top: calc(var(--f-base) * 0.75)
          width: var(--f-base)
          height: var(--f-base)
          background: var(--c-red)
          left: calc(var(--f-base) * -2.4)
          transform: skewX(-12deg)
          transform-origin: 50% 100%

    dt
      font-weight: 700
      margin-top: calc(var(--f-base) * 1.5)

    dd
      margin-left: 0

    p, ul, ol, dl, blockquote, form
      margin: calc(var(--f-base) * 2) 0

    blockquote,
    .box
      background: #fffc radial-gradient(at 0 0, var(--c-blue-20pcnt), var(--c-blue-transp) 75%)
      padding: calc(var(--f-base) * 1.5)

      :first-child
        margin-top: calc(var(--f-base) * .5)
    blockquote
      font-style: italic

    form
      margin: 0
      padding: calc(var(--f-base) * 2)
      background: var(--c-blue-10pcnt)

    input,
    textarea
      width: 100%
      box-sizing: border-box
      border: 1px solid var(--c-blue-50pcnt)
      box-shadow: none
      border-radius: 0
      appearance: none

    input
      height: calc(var(--f-base) * 6)

    label
      font-style: italic

    input:not([type="checkbox"]),
    textarea
      padding: calc(var(--f-base) * 3) calc(var(--f-base) * .5) calc(var(--f-base) * 1)

      & + label
        position: absolute
        left: 1px
        top: 1px
        padding: 0 calc(var(--f-base) * .5)
        font-size: smaller
        background: var(--c-white)

    input[type="checkbox"]
      position: absolute
      opacity: 0

      & + label
        display: inline-block
        padding-left: calc(var(--f-base) * 4)

      & + label::before,
      & + label::after
        content: ''
        position: absolute
        left: 0
        top: calc(var(--f-base) * .4)
      & + label::before
        width: calc(var(--f-base) * 2)
        height: calc(var(--f-base) * 2)
        background: var(--c-white)
        border: 1px solid var(--c-blue-50pcnt)
        box-sizing: border-box
        transform: skewX(-12deg)
        transform-origin: bottom left
      &:checked + label::after
        width: calc(var(--f-base) * 1)
        height: calc(var(--f-base) * 2)
        border: solid var(--c-black)
        border-width: 0 2px 2px 0
        transform: skewX(-12deg) rotate(45deg) translate3d(calc(var(--f-base) * -1), calc(var(--f-base) * -1), 0)
        transform-origin: bottom left

    button[type="submit"]
      margin: calc(var(--f-base) * 2) 0
      padding: calc(var(--f-base) * 1) calc(var(--f-base) * 2)
      transform: skewX(-12deg)
      background: var(--c-blue)
      color: var(--c-white)
      border: none
      text-transform: uppercase
      font-weight: 800
      appearance: none

.chargen
  dt
    font-weight: 400
  dt, dd
    margin: 0
    line-height: calc(var(--f-base) * 3)
  dd
    position: relative
    padding-bottom: var(--f-base)
  dd + dt
    border-top: 1px solid var(--c-blue-20pcnt)
    padding-top: var(--f-base)

  dd::before
    content: attr(data-initial)
    position: absolute
    right: 0
    z-index: -1
    top: calc(var(--f-base) * -1.5)
    font-size: calc(var(--f-base) * 3)
    line-height: calc(var(--f-base) * 3)
    color: var(--c-blue-20pcnt)

.grid
  display: grid
  grid-template-columns: 1fr 1fr
  grid-column-gap: calc(var(--f-base) * 3)

  @media (max-width: 750px)
    grid-template-columns: 1fr

  @media (min-width: 750px)
    &__cell--span-2
      grid-column: 1 / span 2

  &__cell
    position: relative

    &--center
      display: flex
      justify-content: center

form.grid
  grid-gap: calc(var(--f-base) * 1.5)

.accordeon
  & &__head
    cursor: pointer
    position: relative
    padding: calc(var(--f-base) * .5) 0 calc(var(--f-base) * .5) calc(var(--f-base) * 4)
    user-select: none
    outline: none
    transition: all calc(var(--a-fast) * 1ms)
    box-sizing: border-box
    border: 1px solid var(--c-blue-transp)

    &:hover
      background-color: var(--c-blue-10pcnt)

    &:focus:not(:hover)
      border-color: var(--c-blue-50pcnt)

    &::before
      content: ''
      position: absolute
      left: calc(var(--f-base) * 1)
      top: 50%
      display: block
      width: calc(var(--f-base) * 1.25)
      height: calc(var(--f-base) * 1.25)
      box-sizing: border-box
      border: solid var(--c-red-50pcnt)
      border-width: 0 2px 2px 0
      transform-origin: 50%
      transform: translate3d(0, -50%, 0) rotate(-45deg)
      transition: all calc(var(--a-fast) * 1ms)

    &:hover::before
      border-color: var(--c-red)

    &.is-expanded::before
      transform: translate3d(0, -50%, 0) rotate(45deg)

  & &__body
    opacity: 0
    max-height: 0
    margin: 0
    overflow: hidden
    transition: all calc(var(--a-fast) * 1ms)

  &__head.is-expanded + &__body
    opacity: 1
    max-height: 900em
    margin: calc(var(--f-base) * 2) 0 calc(var(--f-base) * 2) calc(var(--f-base) * 3)

.media
  display: flex

  &__text
    flex: 1

  &--left
    padding-right: calc(var(--f-base) * 3)
    order: 1

  &--right
    order: 2

    .page__content &
      text-align: center

  &__pdf
    --h-caption: 30px
    --p: polygon(0 100%, 0 0, 80% 0, 100% 15%, 100% 100%)
    --p-hover: polygon(0 100%, 0 0, 78% 0, 100% 17%, 100% 100%)

    display: inline-flex
    margin: 0 0 var(--h-caption)
    text-align: center
    position: relative

    &::before
      content: ''
      position: absolute
      right: 0
      top: 0
      z-index: 1
      width: 20%
      height: 15%
      box-sizing: border-box
      background-image: linear-gradient(to top right, #fff, #eee 50%, #fff0 50%)
      box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.15)
      transition: width calc(var(--a-fast) * 1ms), height calc(var(--a-fast) * 1ms)

    &:hover::before
      width: 22%
      height: 17%

    picture
      filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.25))
      display: flex

    &:hover picture
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25))

    img
      // clip-path: var(--p) // Probleme mit Chrome et.al.
      transition: all calc(var(--a-fast) * 1ms)

    &:hover img
      // clip-path: var(--p-hover) // Probleme mit Chrome et.al.
      opacity: 0.9

    figcaption
      position: absolute
      left: 0
      right: 0
      bottom: calc(var(--h-caption) * -1)
      line-height: var(--h-caption)
      font-style: italic
      font-size: small

  @media (max-width: 750px)
    flex-direction: column

    &--left
      padding-right: 0

    &__img
      display: flex
      justify-content: center

.foto
  margin: 0
  max-width: 100%

  img
    width: 100%

  &.right
    max-width: 40%
    float: right
    margin-left: calc(var(--f-base) * 3)

  figcaption
    text-align: center
    font-size: small
    font-style: italic

.programm
  .grid__cell
    text-align: center
    background: #fffc radial-gradient(at 0 100%, var(--c-blue-20pcnt), var(--c-blue-transp) 75%)
    img
      max-width: 100%

.picSlot
  position: relative
  z-index: 8
  height: 60vh

  picture
    display: flex
    width: 100%
    height: 100%

  img
    object-fit: cover
    object-position: center
    width: 100%
    height: auto

.footer
  position: relative
  z-index: 7
  display: flex
  justify-content: center
  min-height: 100vh
  background: #fff radial-gradient(at 0 0, var(--c-blue-20pcnt), var(--c-blue-transp) 75%)

  svg
    width: 40vmin
